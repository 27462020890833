import { ErrorHandler, Injectable, Injector } from "@angular/core";
import { SwaggerException } from "@core/http";
import { HotToastService } from "@ngxpert/hot-toast";

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  private toastService!: HotToastService;

  constructor(private injector: Injector) {}

  handleError(error: Error) {
    // Lazily load HotToastService to avoid circular dependency
    if (!this.toastService) {
      this.toastService = this.injector.get(HotToastService);

      this.toastService.defaultConfig = {
        ...this.toastService.defaultConfig,
        stacking: "vertical",
        visibleToasts: 5,
        duration: 5000,
      };
    }

    try {
      if (error instanceof SwaggerException) {
        const response = JSON.parse(error.response);

        const errorMessages: string[] = [];

        if (typeof response === "object") {
          if (response.message) {
            this.toastService.error(response.message);
          } 
          else if (response.detail) {
            this.toastService.error(response.detail);
          }
          else if (typeof response.errors === "object") {
            Object.keys(response.errors).forEach((key) => {
              const propsErrorMessages = response.errors[key];

              errorMessages.push(...propsErrorMessages);
            });
          }
        }

        errorMessages.forEach((message, i) => {
          setTimeout(() => {
            this.toastService.error(message);
          }, i * 500);
        });
      } else {
        this.toastService.error(error?.message ?? error);

        throw error;
      }
    } catch (error: any) {
      this.toastService.warning(
        error.message || "An unexpected error occurreds.",
      );
    }
  }
}
