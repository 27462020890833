import { DOCUMENT } from "@angular/common";
import { Component, Renderer2, inject } from "@angular/core";
import { RouterModule } from "@angular/router";

import { ThemeService, appTheme } from "@core/services";
import { NgxSpinnerModule } from "ngx-spinner";

@Component({
    imports: [RouterModule, NgxSpinnerModule],
    selector: "app-root",
    templateUrl: "./app.component.html",
    styleUrl: "./app.component.scss"
})
export class AppComponent {
  title = "ResQueue-dashboard";

  private readonly document = inject(DOCUMENT);
  private readonly renderer2: Renderer2 = inject(Renderer2);
  private readonly themeService = inject(ThemeService);

  ngOnInit(): void {
    //  this.renderer2.removeClass(this.document.body, 'h-screen')
    this.themeService.setTheme(appTheme.main);
  }
}
