import { inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { UsersClientV1 } from "@core/http";
import { UserProfileDto } from "@core/models";

@Injectable({ providedIn: "root" })
export class UserService {
  private userClient = inject(UsersClientV1);

  getUserProfile(): Observable<UserProfileDto> {
    return this.userClient.getUserProfile();
  }
}
